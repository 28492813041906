import { type AITool } from "@app/_metadata";
import LEOLogo from "@shared-assets/leo-logo.svg";
import { Badge } from "@shared-components/shadcn/badge";
import { api } from "@shared-server/api";
import { cn } from "@shared-utils/ui";
import Image from "next/image";

/**
 * LEO Ident used in TopBar and auth prompt
 *
 * @param props IdentProps
 */

interface IdentProps {
  invert?: boolean;
  aiTool?: AITool;
}

export const Ident = (props: IdentProps) => {
  const envQuery = api.utils.env.useQuery();
  const env = envQuery.data;

  /**
   * Render
   */
  return (
    <div
      className={cn("mr-4 flex flex-row justify-center", {
        "text-gray-200": props.invert,
      })}
    >
      <Image
        priority={false}
        className={cn("mr-3 w-[40px] dark:invert", {
          invert: props.invert,
        })}
        src={LEOLogo as string}
        alt="LEO logo"
      />
      <div data-dd-privacy="allow">
        <div className="mt-.5 -mb-1 text-xs font-extrabold">{"LEOPharma"}</div>
        <span className="text-lg font-semibold">
          {props.aiTool?.meta.name || "LEO AI"}
          {env && env !== "prod" && (
            <Badge
              size="sm"
              className={cn("mx-2 text-white", {
                "bg-leo-sunflower-600": env === "test",
                "bg-leo-peacock-600": env === "dev",
                "bg-slate-500": env === "local",
              })}
            >
              {env.toUpperCase()}
            </Badge>
          )}
        </span>
      </div>
    </div>
  );
};
