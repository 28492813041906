import { Label } from "@shared-components/shadcn/label";
import { Switch } from "@shared-components/shadcn/switch";
import { useState } from "react";

export const DarkModeSwitch = () => {
  const [isDarkmode, setIsDarkmode] = useState(
    document.getElementsByTagName("html")[0]?.classList?.contains("dark")
      ? true
      : false,
  );
  return (
    <div className="flex items-center space-x-2 px-2 py-1">
      <Switch
        checked={isDarkmode}
        onCheckedChange={(value) => {
          // update internal state
          setIsDarkmode(value);

          // save to cookie
          document.cookie = `theme=${value ? "dark" : "light"};`;

          // update html class
          document
            .getElementsByTagName("html")[0]
            ?.classList.toggle("dark", value);
          document
            .getElementsByTagName("html")[0]
            ?.classList.toggle("light", !value);
        }}
        id="dark-mode"
      />
      <Label htmlFor="dark-mode">{"Dark mode"}</Label>
    </div>
  );
};
