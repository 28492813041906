// Supported feature flags
export const FeatureFlag = [
  "CHAT_AI__WEB_READER__187473",
  "ISEARCH__APP__188825",
  "TRAINING_AI__APP__189322",
] as const;
export type FeatureFlagType = (typeof FeatureFlag)[number];

// Map to print feature flags
export const featuresMap: { [key in FeatureFlagType]: string } = {
  CHAT_AI__WEB_READER__187473: "Chat AI Plugin Web Reader",
  ISEARCH__APP__188825: "iSearch",
  TRAINING_AI__APP__189322: "Training AI",
};
