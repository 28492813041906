import {
  type ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@shared-components/shadcn/chart";
import { api } from "@shared-server/api";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Line,
  XAxis,
  YAxis,
  Legend,
} from "recharts";

const chartConfig: ChartConfig = {
  count: {
    label: "Images",
  },
};

interface ImagesProps {
  startDate: Date;
  endDate: Date;
}

export const Images = (props: ImagesProps) => {
  // Query
  const coreMetricsQuery = api.coreMetrics.imagesAttempted.useQuery({
    startDate: props.startDate,
    endDate: props.endDate,
  });
  const chartData = coreMetricsQuery.data?.chartData ?? [];

  return (
    <div>
      <h2 className="mb-4">{"Images"}</h2>
      <ChartContainer
        config={chartConfig}
        className="aspect-auto h-[150px] w-full"
      >
        <ComposedChart accessibilityLayer data={chartData}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            minTickGap={32}
            tickFormatter={(value: string) => {
              const date = new Date(value);
              return date.toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
              });
            }}
          />
          <YAxis
            yAxisId="countItems"
            dataKey={"countItems"}
            interval={1}
            orientation="left"
          />
          <YAxis
            yAxisId="countItemsCumulative"
            dataKey={"countItemsCumulative"}
            interval={1}
            orientation="right"
          />
          <ChartTooltip
            content={
              <ChartTooltipContent
                className="w-[150px]"
                nameKey="date"
                labelFormatter={(value: string) => {
                  return value;
                }}
              />
            }
          />
          <Legend />
          <Bar
            yAxisId="countItems"
            dataKey={"countItems"}
            className="fill-leo-sunflower-600"
            fill="#eaaa00"
          />
          <Line
            yAxisId="countItemsCumulative"
            dataKey={"countItemsCumulative"}
            dot={false}
            className="fill-leo-sunflower-600"
            stroke="#eaaa00"
          />
        </ComposedChart>
      </ChartContainer>
    </div>
  );
};
