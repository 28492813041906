import { Button } from "@shared-components/shadcn/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@shared-components/shadcn/popover";
import { FlaskConical } from "lucide-react";
import { api } from "@shared-server/api";
import { featuresMap, type FeatureFlagType } from "@shared-utils/constants";

export const ActiveFeatureFlags = () => {
  const activeFeatureFlags = api.feature.allEnabledForMe.useQuery();

  return (
    <Popover>
      <PopoverTrigger
        asChild
        className={
          activeFeatureFlags.data && activeFeatureFlags.data.length > 0
            ? ""
            : "hidden"
        }
      >
        <Button variant={"ghost"} className="group relative h-14 px-2">
          <FlaskConical size={22} strokeWidth={1.5} />
          <div className="absolute left-[18px] top-[12px] h-[4px] w-[4px] animate-[loader-ellipsis-with-idle_30s_0.0s_infinite_ease-in-out_both] rounded-full bg-blue-600 group-hover:animate-[loader-ellipsis_3.4s_0.0s_infinite_ease-in-out_both]"></div>
          <div className="absolute left-[22px] top-[8px] h-[5px] w-[5px] animate-[loader-ellipsis-with-idle_30s_0.4s_infinite_ease-in-out_both] rounded-full bg-yellow-600 group-hover:animate-[loader-ellipsis_3.4s_0.4s_infinite_ease-in-out_both]"></div>
          <div className="absolute left-[14px] top-[2px] h-[8px] w-[8px] animate-[loader-ellipsis-with-idle_30s_0.8s_infinite_ease-in-out_both] rounded-full bg-green-600 group-hover:animate-[loader-ellipsis_3.4s_0.8s_infinite_ease-in-out_both]"></div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="m-1">
        <div className="flex flex-col">
          <b>{"Experimental features:"}</b>
          <ul className="list-disc pl-5">
            {activeFeatureFlags.data?.map((flag: FeatureFlagType) => (
              <li key={flag}>
                <small>{featuresMap[flag]}</small>
              </li>
            ))}
          </ul>
        </div>
      </PopoverContent>
    </Popover>
  );
};
