import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@shared-components/shadcn/table";
import { api } from "@shared-server/api";

interface SummaryTableProps {
  startDate: Date;
  endDate: Date;
}

const prettifyItemName = (itemName: string): string => {
  // Split key on capital letters and separate by space,
  // then lowercase, and capitalize first letter.
  // Example: ChatsWithFilesAttempted -> Chats with files attempted
  if (!itemName) return itemName;
  const lower = itemName
    .split(/(?=[A-Z])/)
    .join(" ")
    .toLowerCase();
  return lower.charAt(0).toUpperCase() + lower.slice(1);
};

export const SummaryTable = (props: SummaryTableProps) => {
  const chatsAttempted = api.coreMetrics.chatsAttempted.useQuery({
    startDate: props.startDate,
    endDate: props.endDate,
  });

  const chatsWithFilesAttempted =
    api.coreMetrics.chatsWithFilesAttempted.useQuery({
      startDate: props.startDate,
      endDate: props.endDate,
    });

  const imagesAttempted = api.coreMetrics.imagesAttempted.useQuery({
    startDate: props.startDate,
    endDate: props.endDate,
  });

  const imagesErrored = api.coreMetrics.imagesErrored.useQuery({
    startDate: props.startDate,
    endDate: props.endDate,
  });

  const translationsFilesAttempted =
    api.coreMetrics.translationsFilesAttempted.useQuery({
      startDate: props.startDate,
      endDate: props.endDate,
    });

  const translationsTextAttempted =
    api.coreMetrics.translationsTextAttempted.useQuery({
      startDate: props.startDate,
      endDate: props.endDate,
    });

  const tableData = {
    chatsAttempted: {
      countItems: chatsAttempted.data?.countItems ?? 0,
      countUsers: chatsAttempted.data?.countUsers ?? 0,
    },
    chatsWithFilesAttempted: {
      countItems: chatsWithFilesAttempted.data?.countItems ?? 0,
      countUsers: chatsWithFilesAttempted.data?.countUsers ?? 0,
    },
    imagesAttempted: {
      countItems: imagesAttempted.data?.countItems ?? 0,
      countUsers: imagesAttempted.data?.countUsers ?? 0,
    },
    imagesErrored: {
      countItems: imagesErrored.data?.countItems ?? 0,
      countUsers: imagesErrored.data?.countUsers ?? 0,
    },
    translationsFilesAttempted: {
      countItems: translationsFilesAttempted.data?.countItems ?? 0,
      countUsers: translationsFilesAttempted.data?.countUsers ?? 0,
    },
    translationsTextAttempted: {
      countItems: translationsTextAttempted.data?.countItems ?? 0,
      countUsers: translationsTextAttempted.data?.countUsers ?? 0,
    },
  };

  return (
    <div>
      <h2 className="mb-4">{"Summary"}</h2>
      <div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Item</TableHead>
              <TableHead>Count Items Cumulative</TableHead>
              <TableHead>Count Users Cumulative</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Object.entries(tableData).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell>{prettifyItemName(key)}</TableCell>
                <TableCell>{value.countItems}</TableCell>
                <TableCell>{value.countUsers}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
